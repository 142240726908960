var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { excludeQueryParams } from '../../url/query-excluder/excludeQueryParams';
import Vue from 'vue';
import { Component, Prop } from '~/utility/pu-class-decorator';
export var GenericLink = (_dec = Prop({
  required: false
}), _dec2 = Prop({
  required: false
}), _dec3 = Prop({
  required: false
}), _dec4 = Prop({
  required: false,
  default: false
}), _dec5 = Prop({
  default: true
}), _dec6 = Prop(), Component(_class = (_class2 = class GenericLink extends Vue {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "name", _descriptor, this);
    _initializerDefineProperty(this, "params", _descriptor2, this);
    _initializerDefineProperty(this, "query", _descriptor3, this);
    _initializerDefineProperty(this, "disabled", _descriptor4, this);
    _initializerDefineProperty(this, "prefetch", _descriptor5, this);
    _initializerDefineProperty(this, "element", _descriptor6, this);
  }
  get linkData() {
    return {};
  }
  get cleanedLinkData() {
    var linkDataResolved = this.resolveLinkData(this.linkData);
    return this.cleanEmptyQueryElements(linkDataResolved);
  }
  render(h) {
    if (this.name) {
      return h(this.element || 'nuxt-link', {
        props: {
          to: {
            name: this.name,
            params: this.params,
            query: this.query
          },
          event: this.disabled ? '' : 'click',
          prefetch: this.prefetch
        },
        nativeOn: this.$listeners
      }, this.$slots.default);
    } else {
      var isLoaded = !!this.linkData.query || !!this.linkData.path || !!this.linkData.name;
      if (isLoaded) {
        return h(this.element || 'nuxt-link', {
          props: {
            to: this.$puLocalePath(this.cleanedLinkData),
            event: this.disabled ? '' : 'click',
            prefetch: this.prefetch
          },
          nativeOn: this.$listeners
        }, this.$slots.default);
      } else {
        return h('span', this.$slots.default);
      }
    }
  }
  cleanEmptyQueryElements(linkDataResolved) {
    var cleaned = {};
    for (var [key, value] of Object.entries(linkDataResolved.query)) {
      if (value) cleaned[key] = value;
    }
    return _objectSpread(_objectSpread({}, linkDataResolved), {}, {
      query: cleaned
    });
  }
  resolveLinkData(linkData) {
    var linkDataNonempty = ensureLinkDataIsNotEmpty(linkData);
    var queryExcluded = excludeQueryParams({
      query: linkDataNonempty.query,
      excludeType: this.excludeType,
      forceQueryParams: linkData.forceQueryParams
    });
    return _objectSpread(_objectSpread({}, linkDataNonempty), {}, {
      query: queryExcluded
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "name", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "params", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "query", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "prefetch", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "element", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class2)) || _class);
function ensureLinkDataIsNotEmpty(linkData) {
  return {
    hash: linkData.hash || undefined,
    name: linkData.name || '',
    path: linkData.path || '',
    query: linkData.query || {},
    params: linkData.params || {}
  };
}