import { Context, Plugin } from '@nuxt/types'
import { rootContainer } from '~/container'
import { SYMBOLS } from './symbols'

const plugin: Plugin = ({ app }: Context) => {
  if (app.i18n && !rootContainer.isBound('i18n')) {
    rootContainer.bind('i18n').toConstantValue(app.i18n)
  }

  if (app.$sentry && !rootContainer.isBound(SYMBOLS.SentryInstance)) {
    rootContainer.bind(SYMBOLS.SentryInstance).toConstantValue(app.$sentry)
  }

  if (app.$sentryReady && !rootContainer.isBound(SYMBOLS.SentryReadyInstance)) {
    rootContainer
      .bind(SYMBOLS.SentryReadyInstance)
      .toConstantValue(app.$sentryReady)
  }
}
export default plugin
