import { Route } from '../domain/Route'
import { RouteHistoryService } from '../domain/RouteHistoryService'

export class RouteHistoryServiceLocal extends RouteHistoryService {
  private history: Route[] = []

  routeChanged(route: Route) {
    this.history.push(route)
    if (this.history.length > 15) {
      this.history.shift()
    }
  }

  getHistory(): Route[] {
    return this.history
  }

  getReferrer(): Route | undefined {
    if (this.history.length > 1) {
      return this.history[this.history.length - 2]
    }
    return undefined
  }
}
