import { Route } from 'vue-router'
import CATEGORY_MAP from '~/json/catRoot.json'
import { ensureArray } from '../../../utility/array'
import { GLAMPING_CATEGORIES } from '../../../utility/glamping'
import { getMetaFromRoute } from '../../../utility/route'
import { getPathElements } from '../getPathElements'

export function removeUndefinedFields<T extends object>(obj: T): Partial<T> {
  // Source: https://stackoverflow.com/a/40924449/761265
  return Object.keys(obj).reduce((acc, key) => {
    const _acc = acc
    if (obj[key] !== undefined && obj[key] !== null) _acc[key] = obj[key]
    return _acc
  }, {})
}

export function routePathToQuery(nextRoute: Route) {
  const [hierarchyPath, facetPath] = getPathElements(nextRoute)
  const facet = getFacetQueryParam(nextRoute.query, facetPath)
  const type = getCategoryQueryParam(nextRoute)
  return { facet, hierarchyPath, type }
}

function getFacetQueryParam(query: Record<string, any>, facetPath: string) {
  const facetArray = ensureArray(query.facet)
    .concat((facetPath || '').split('/'))
    .filter((f) => !!f)
    .filter(uniqueArrayFilter) as string[]
  return facetArray.length > 0 ? facetArray : undefined
}

function getCategoryQueryParam(nextRoute: Route) {
  const meta = getMetaFromRoute(nextRoute)
  const slug = meta.category
  if (!slug) return
  if (slug.startsWith('glamping')) {
    return GLAMPING_CATEGORIES
  }
  const categoryId = Object.keys(CATEGORY_MAP).find(
    (key) => CATEGORY_MAP[key] === slug,
  )
  return categoryId ? [categoryId] : undefined
}

const uniqueArrayFilter = <T>(value: T, index: number, array: T[]): boolean =>
  array.indexOf(value) === index
