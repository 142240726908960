import { SearchFilters } from '~/apps/search/schemas'
import { defaultPageSize } from '~/apps/search/defaults'
import { GeoCircle } from '~~/utility/geo/GeoLocation'

const datesURLPart = function* (filters: SearchFilters) {
  if (!filters.dates) return
  yield { filterName: 'arrive', filterValue: filters.dates.arrive }
  yield { filterName: 'depart', filterValue: filters.dates.depart }
}

const categoryIdsURLPart = function* (filters: SearchFilters) {
  if (!filters.categoryIds) return
  for (const category of filters.categoryIds) {
    yield { filterName: 'type', filterValue: category }
  }
}

const pageURLPart = function* (filters: SearchFilters) {
  if (filters.page && filters.page > 1) {
    yield { filterName: 'page', filterValue: filters.page.toString() }
  }
}

const sortURLPart = function* (filters: SearchFilters) {
  if (!filters.sort) return
  yield { filterName: 'sort', filterValue: filters.sort }
}

const facetsURLPart = function* (filters: SearchFilters) {
  if (!filters.facets) return
  for (const facet of filters.facets) {
    yield { filterName: 'facet', filterValue: facet }
  }
}

const limitURLPart = function* (filters: SearchFilters) {
  const limit = filters.limit || defaultPageSize
  yield { filterName: 'limit', filterValue: limit.toString() }
}

const hierarchyPathURLPart = function* (filters: SearchFilters) {
  if (!filters.hierarchyPath) return
  yield { filterName: 'path', filterValue: filters.hierarchyPath }
}

const areaURLPart = function* (filters: SearchFilters) {
  let area: GeoCircle
  if (filters.bounds) {
    yield { filterName: 'bounds', filterValue: JSON.stringify(filters.bounds) }
    return
  } else if (filters.area) {
    area = filters.area
  } else return

  const pointParams = area.isEmptyPoint
    ? { within: area.rangeMiles }
    : { lat: area.lat, lng: area.lng, within: area.rangeMiles }
  for (const k of Object.keys(pointParams)) {
    yield { filterName: k, filterValue: pointParams[k] }
  }
}

const partyURLPart = function* (filters: SearchFilters) {
  if (filters.party?.adults && filters.party?.adults !== 2) {
    yield { filterName: 'adults', filterValue: String(filters.party.adults) }
  }
  if (filters.party?.childAges?.length) {
    yield {
      filterName: 'child_ages',
      filterValue: filters.party.childAges.join(','),
    }
  }
}

const priceRangeURLPart = function* (filters: SearchFilters) {
  if (!filters.priceRange) return
  if (filters.priceRange.min && !isNaN(filters.priceRange.min)) {
    yield {
      filterName: 'min_price',
      filterValue: filters.priceRange.min.toString(),
    }
  }
  if (filters.priceRange.max && !isNaN(filters.priceRange.max)) {
    yield {
      filterName: 'max_price',
      filterValue: filters.priceRange.max.toString(),
    }
  }
}

const fieldsURLPart = function* (filters: SearchFilters) {
  if (!filters.fields) return
  yield { filterName: 'fields', filterValue: filters.fields.join(',') }
}

const baseURLPart = function* (filters: SearchFilters) {
  if (!filters.base) return
  yield { filterName: 'base', filterValue: filters.base }
}

const withinURLPart = function* (filters: SearchFilters) {
  if (!filters.within) return
  yield { filterName: 'within', filterValue: filters.within }
}

const alfredVersionURLPart = function* (_) {
  yield {
    filterName: 'av',
    filterValue: process.env.ALFRED_VERSION || 'noversion',
  }
}

const urlParts = [
  fieldsURLPart,
  datesURLPart,
  categoryIdsURLPart,
  pageURLPart,
  sortURLPart,
  facetsURLPart,
  limitURLPart,
  hierarchyPathURLPart,
  areaURLPart,
  partyURLPart,
  priceRangeURLPart,
  baseURLPart,
  withinURLPart,
  // alfredVersionURLPart,
]

export function* parseSearchFiltersToUrl(filters: SearchFilters) {
  for (const funcToRun of urlParts) {
    for (const cleaned of funcToRun(filters)) {
      yield cleaned
    }
  }
}
