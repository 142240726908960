import * as z from 'zod'
import { fetchWithCache } from '~/utility/fetchWithCache'
import { getLangFallbackUrl } from '~/lang/utils/langfetch'
import { getTransformObject } from '../../shared/photos/transforms'

const nationalParkPhotoUrlSchema = z.object({
  photo: z.string(),
})
const nationalParkSchema = z.object({
  name: z.string(),
  url: z
    .string()
    .transform((url) =>
      url
        ? url
          .replace(/^\/([^/]*\/)?campsites\/(.+)$/, '/$2')
          .replace(/^\/+/, '')
        : '',
    ),
  image: nationalParkPhotoUrlSchema,
})

const nationalParksSchema = nationalParkSchema.array()

type NationalParkPhotoUrl = z.infer<typeof nationalParkPhotoUrlSchema>
export type NationalPark = z.infer<typeof nationalParkSchema>

export async function getNationalParks(
  langCode: string,
): Promise<NationalPark[]> {
  const url = getLangFallbackUrl(langCode, '/_/national-parks/')
  const results = await fetchWithCache({ url })
  return nationalParksSchema.parse(mapResults(results))
}

function mapResults(results: any) {
  return results.map((result) => {
    result.image = getTransformObject<NationalParkPhotoUrl>(
      'nationalParks',
      result.image,
    )
    return result
  })
}
