
import { SearchLinkGenerator } from '../../url/generators/SearchLinkGenerator'
import { Component, Prop } from '~/utility/pu-class-decorator'
import { ensureArray } from '~/utility/array'
import { isCampsiteDetailsRoute, isSearchRoute } from '../domain/Route'
import { GenericLink } from '../link-generic/GenericLink'
import { GenericLinkData } from '../link-generic/GenericLinkData'
import { routeToFilters } from '../infrastructure/routeToFilters'
import { isSearchUrlRoute } from '../../search/isSearchUrlComponent'

@Component
export default class PuSearchWizardLink extends GenericLink {
  @Prop({ default: 'searchedit' })
    wizardPage?: 'searchedit' | 'maps'

  get linkData(): GenericLinkData {
    const urlGenerator = new SearchLinkGenerator()
    const urlData = urlGenerator.getUrlData({
      category: this.wizardPage,
      excludeType: 'searchWizard',
      filters: this.filters,
      customQueryParams: this.customQueryParams,
    })
    return {
      ...urlData,
      forceQueryParams: this.customQueryParams
        ? Object.keys(this.customQueryParams)
        : undefined,
    }
  }

  private get filters() {
    const filters = routeToFilters(this.$route)
    if (isCampsiteDetailsRoute(this.$route)) {
      filters.hierarchyPath = this.getCampsiteDetailsHierarchyPath()
    } else if (!isSearchUrlRoute(this.$route)) {
      filters.hierarchyPath = ''
    }
    return filters
  }

  private getCampsiteDetailsHierarchyPath() {
    let hierarchyPath: string | undefined
    const referrer = this.$routerClient.getReferrer()
    if (referrer && isSearchRoute(referrer)) {
      hierarchyPath = routeToFilters(referrer).hierarchyPath
    }

    return hierarchyPath
  }

  private get customQueryParams() {
    return {
      placename: ensureArray(this.$route.query.placename)[0] || undefined,
      q: ensureArray(this.$route.query.q)[0] || '',
    }
  }
}
