
import { HomepageLinkGenerator } from '../../url/generators/HomepageLinkGenerator'
import { Component, Prop } from '~/utility/pu-class-decorator'
import { GenericLink } from '../link-generic/GenericLink'
import { GenericLinkData } from '../link-generic/GenericLinkData'
import { routeToFilters } from '../infrastructure/routeToFilters'

@Component
export default class PuHomepageLink extends GenericLink {
  @Prop({ default: 'homepage' })
    excludeType: string

  get linkData(): GenericLinkData {
    const filters = routeToFilters(this.$route)
    const urlGenerator = new HomepageLinkGenerator()
    return urlGenerator.getUrlData(filters)
  }
}
