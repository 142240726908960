import { Route } from 'vue-router'
import { SearchFiltersUrl } from '../../url/search-filters/SearchFiltersUrl'
import { injectable } from '~/container'
import { getMetaFromRoute } from '~/utility/route'
import { SearchUrlState } from '../domain/SearchUrlState'
import { removeUndefinedFields, routePathToQuery } from './utils'

@injectable()
export class RouteParser {
  routeToSearchUrlState(
    nextRoute: Route,
    currentState?: SearchUrlState,
  ): SearchUrlState {
    const nextQuery = nextRoute.query || {}
    // sometimes route.meta is an array and sometimes an object
    const meta = getMetaFromRoute(nextRoute)
    const filters = {
      ...SearchFiltersUrl.urlToFilters({
        ...this.getCurrentQuery(nextRoute, currentState),
        ...removeUndefinedFields(nextQuery),
        ...removeUndefinedFields(routePathToQuery(nextRoute)),
      }),
      base: meta.type,
    }

    return {
      rawQuery: nextRoute.query,
      filters,
      queryForFilters: SearchFiltersUrl.filtersToUrl(filters).query,
    }
  }

  private getCurrentQuery(nextRoute: Route, currentState?: SearchUrlState) {
    const prependCurrentFilters = !nextRoute.query && !!currentState
    return prependCurrentFilters ? currentState?.queryForFilters : {}
  }
}
