import { injectable } from '~/container'
import { getDefaultSearchFilters } from '../../search/defaults'
import { SearchFilters } from '../../search/schemas'
import { UrlQuery } from '../../url/domain/UrlQuery'

@injectable()
export abstract class SearchUrlState {
  filters: SearchFilters
  queryForFilters: UrlQuery
  rawQuery: UrlQuery
}

export function getEmptySearchUrlState(): SearchUrlState {
  return {
    filters: getDefaultSearchFilters(),
    queryForFilters: {},
    rawQuery: {},
  }
}
