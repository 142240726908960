import { Route } from 'vue-router'
import { getMetaFromRoute } from '~/utility/route'
import { SearchFilters } from '../../search/schemas'
import { SearchFiltersUrl } from '../../url/search-filters/SearchFiltersUrl'
import { removeUndefinedFields, routePathToQuery } from './utils'

// when you just need to get the filters from a route
export function routeToFilters(nextRoute: Route): SearchFilters {
  const meta = getMetaFromRoute(nextRoute)
  const nextQuery = nextRoute.query || {}
  const queries = {
    ...removeUndefinedFields(nextQuery),
    ...removeUndefinedFields(routePathToQuery(nextRoute)),
  }
  const filters: SearchFilters = {
    ...SearchFiltersUrl.urlToFilters(queries),
    base: meta.type,
  }
  if (nextRoute.name === 'campsite-detail') {
    // remove hierarchy path and base
    delete filters.hierarchyPath
    delete filters.base
  }
  return filters
}
