import { injectable } from '~/container'
import { Container } from 'inversify'
import { rootContainer } from '~/container'
import sentryCapture from '~/utility/sentryCapture'
import { RouteHistoryService } from './domain/RouteHistoryService'
import { RouterClient } from './domain/RouterClient'
import { RouteHistoryServiceLocal } from './infrastructure/RouteHistoryServiceLocal'
import { RouteHistoryServiceLocalStub } from './infrastructure/RouteHistoryServiceLocalStub'
import { RouteParser } from './infrastructure/RouteParser'
import { RouterBasedObservableSearchUrlStateService } from './infrastructure/RouterBasedObservableSearchUrlStateService'
import { SearchUrlStateUpdater } from './infrastructure/SearchUrlStateUpdater'

export const puLinksContainer = new Container()
puLinksContainer.parent = rootContainer
puLinksContainer.bind(RouteParser).toSelf()
puLinksContainer
  .bind(RouterBasedObservableSearchUrlStateService)
  .toSelf()
  .inRequestScope()

puLinksContainer.bind(SearchUrlStateUpdater).toSelf().inRequestScope()

@injectable()
export class RouterClientImpl implements RouterClient {
  private obs: RouterBasedObservableSearchUrlStateService
  private searchUrlState: SearchUrlStateUpdater
  private routerHistoryService: RouteHistoryService

  constructor() {
    // There was an issue where RouterBasedObservableSearchUrlStateService
    // had two instance created when loading everything from inversify,
    // one got the event handler for a route change set on it and the other
    // got the event triggered on it so URL filters weren't properly being
    // set in components that read them. This gives us one instance as this
    // class definition is in the same file as the DI container anyway.
    this.routerHistoryService = process.client
      ? new RouteHistoryServiceLocal()
      : new RouteHistoryServiceLocalStub()
    this.searchUrlState = new SearchUrlStateUpdater(
      puLinksContainer.get(RouterBasedObservableSearchUrlStateService),
    )
    this.obs = this.searchUrlState.getObservableService()
  }

  getPresenter() {
    return this.searchUrlState.getReactivePresenter()
  }

  getReferrer() {
    return process.client ? this.routerHistoryService.getReferrer() : undefined
  }

  getHistory() {
    return process.client ? this.routerHistoryService.getHistory() : []
  }

  memoryLeakCheckCount = 0

  routeChanged(r) {
    if (process.server && this.memoryLeakCheckCount > 10) {
      const error = new Error('RouterClient memoryLeakCheckCount exceeded 10')
      sentryCapture(error, rootContainer)
    }
    this.obs.routeChanged(r)
    this.routerHistoryService.routeChanged(r)
  }
}

puLinksContainer.bind(RouterClient).to(RouterClientImpl).inRequestScope()
puLinksContainer.bind(RouterClientImpl).to(RouterClientImpl).inRequestScope()
