import { VueReactivePresenterUpdater } from '~/utility/vue/VueReactivePresenterUpdater'
import {
  SearchUrlState,
  getEmptySearchUrlState,
} from '../domain/SearchUrlState'
import { RouterBasedObservableSearchUrlStateService } from './RouterBasedObservableSearchUrlStateService'

export class SearchUrlStateUpdater extends VueReactivePresenterUpdater<SearchUrlState> {
  constructor(
    private observableService: RouterBasedObservableSearchUrlStateService,
  ) {
    super({ initial: getEmptySearchUrlState() })
    this.observableService.subscribe((newUrl) => {
      super.reactivelyUpdatePresenter(newUrl)
    })
  }

  getObservableService() {
    return this.observableService
  }
}
