
import { SearchFilters } from '../../search/schemas'
import { SearchFiltersUrl } from '../../url/search-filters/SearchFiltersUrl'
import { Component, Prop } from '~/utility/pu-class-decorator'
import { GenericLink } from '../link-generic/GenericLink'
import { GenericLinkData } from '../link-generic/GenericLinkData'
import { PuCampsiteLinkProps } from './PuCampsiteLinkProps'
import { routeToFilters } from '../infrastructure/routeToFilters'

@Component
export default class PuCampsiteLink
  extends GenericLink
  implements PuCampsiteLinkProps
{
  @Prop({
    required: true,
    type: String,
    validator: (s: string) => s.length > 0,
  })
    hierarchyPath: string

  @Prop({
    required: true,
    type: String,
    validator: (s: string) => s.length > 0,
  })
    slug: string

  @Prop({ required: false, type: String })
    pitchtypeId: string

  @Prop({ required: false, type: Object, default: () => ({}) })
    filters: Partial<SearchFilters>

  @Prop({ required: false, default: 'campsite' })
    excludeType: string

  @Prop({ required: false, type: String })
    reviewId?: string

  @Prop({ default: false, type: Boolean })
    forceReviewId: boolean

  @Prop({ default: true })
    prependUrlSearchFilters: boolean

  @Prop({ default: () => [] })
    forceQueryParams: string[]

  @Prop({ type: String })
    hash?: string

  get linkData(): GenericLinkData {
    const path = this.hierarchyPath
    return {
      query: {
        // @ts-ignore
        ...this.$route.query,
        ...SearchFiltersUrl.filtersToUrl(this.queryFilters).query,
        pitchtype: this.pitchtypeId || undefined,
        review: this.reviewId,
      },
      path: `/campsites/${path}${this.slug}/`,
      forceQueryParams: this.forceReviewId
        ? this.forceQueryParams.concat(['review'])
        : this.forceQueryParams,
      hash: this.hash ?? this.reviewHash,
    }
  }

  private get queryFilters() {
    const filters = routeToFilters(this.$route)
    return this.prependUrlSearchFilters
      ? {
        ...filters,
        ...this.filters,
      }
      : { ...this.filters }
  }

  private get reviewHash() {
    return this.reviewId && this.forceReviewId
      ? `#reviews_${this.reviewId}`
      : undefined
  }
}
