import { inject, injectable } from '~/container'
import { EventEmitter } from '~/utility/EventEmitter'
import {
  default as sentryCapture,
  sentryCaptureMessage,
} from '~/utility/sentryCapture'
import { ObservableSearchUrlStateService } from '../domain/ObservableSearchUrlStateService'
import { Route } from '../domain/Route'
import { RouterClient } from '../domain/RouterClient'
import {
  SearchUrlState,
  getEmptySearchUrlState,
} from '../domain/SearchUrlState'
import { RouteParser } from './RouteParser'

const eventName = 'searchUrlStateChanged'

interface Events {
  [eventName]: SearchUrlState
}

@injectable()
export class RouterBasedObservableSearchUrlStateService
implements ObservableSearchUrlStateService, RouterClient
{
  private emitter: EventEmitter<Events> = new EventEmitter()
  private current?: SearchUrlState

  memoryLeakCheckCount = 0

  @inject(RouteParser)
  private parser: RouteParser

  subscribe(handler: (searchUrlState: SearchUrlState) => void): () => void {
    return this.emitter.on(eventName, handler)
  }

  get(): SearchUrlState {
    if (!this.current) {
      sentryCaptureMessage(
        'SearchUrlState not yet loaded in RouterBasedObservableSearchUrlStateService',
      )
      return getEmptySearchUrlState()
    }
    return this.current
  }

  routeChanged(route: Route) {
    if (process.server && this.memoryLeakCheckCount > 10) {
      const error = new Error(
        'RouterBasedObservableSearchUrlStateService memoryLeakCheckCount exceeded 10',
      )
      throw error
      sentryCapture(error)
    }

    try {
      this.processRoute(route)
    } catch (err) {
      const error = err as Error
      error.name =
        'RouterBasedObservableSearchUrlStateService routeChanged error'
      sentryCapture(error)
    }
  }

  private processRoute(route: Route) {
    const updated = this.parser.routeToSearchUrlState(route, this.current)
    this.current = updated
    this.emitter.emit(eventName, this.current)
  }
}
