
import { Component, Prop } from '~/utility/pu-class-decorator'
import { SearchFilters } from '../../search/schemas'
import { SearchLinkGenerator } from '../../url/generators/SearchLinkGenerator'
import { routeToFilters } from '../infrastructure/routeToFilters'
import { GenericLink } from '../link-generic/GenericLink'
import { GenericLinkData } from '../link-generic/GenericLinkData'
import { PuSearchLinkProps } from './PuSearchLinkProps'

@Component
export default class PuSearchLink
  extends GenericLink
  implements PuSearchLinkProps
{
  @Prop({ default: 'campsites' })
    category: string

  @Prop({ default: 'searchDefault' })
    excludeType: string

  @Prop()
    facet?: string

  @Prop()
    searchFilters?: SearchFilters

  @Prop()
    modifyFilters?: SearchFilters

  @Prop()
    hierarchyPath?: string

  @Prop()
    customQueryParams?: Record<string, string | undefined>

  @Prop({ default: true })
    moveSingleFacetIntoUrl: boolean

  get linkData(): GenericLinkData {
    const urlGenerator = new SearchLinkGenerator()

    const urlData = urlGenerator.getUrlData({
      category: this.category,
      excludeType: this.excludeType,
      facet: this.handleFacet(),
      hierarchyPath: this.hierarchyPath,
      filters: this.handleFilters(),
      customQueryParams: this.customQueryParams,
    })
    return {
      ...urlData,
      forceQueryParams: this.customQueryParams
        ? Object.keys(this.customQueryParams)
        : undefined,
    }
  }

  handleFacet() {
    if (
      !this.facet &&
      this.moveSingleFacetIntoUrl &&
      this.searchFiltersResolved.facets?.length === 1
    ) {
      return this.searchFiltersResolved.facets[0]
    }
    return this.facet
  }

  handleFilters() {
    const filters = { ...this.searchFiltersResolved }
    if (
      !this.facet &&
      this.moveSingleFacetIntoUrl &&
      filters.facets?.length === 1
    ) {
      filters.facets = []
    }
    return filters
  }

  get searchFiltersResolved(): SearchFilters {
    const searchUrlStateFilters = routeToFilters(this.$route)
    return {
      ...(this.searchFilters || searchUrlStateFilters),
      ...(this.modifyFilters || {}),
    }
  }
}
