
import { Component, Prop } from '~/utility/pu-class-decorator'
import { GenericLink } from '../link-generic/GenericLink'
import { GenericLinkData } from '../link-generic/GenericLinkData'
import { PuLinkProps } from './PuLinkProps'

@Component
export default class PuLink extends GenericLink implements PuLinkProps {
  @Prop()
    query?: Record<string, any>

  @Prop()
    excludeType?: string

  @Prop()
    name?: string

  @Prop()
    path?: string

  @Prop()
    forceQueryParams?: string[]

  @Prop()
    params?: Record<string, any>

  get linkData(): GenericLinkData {
    const { path, query } = this.moveSingleFacetToPath()
    return {
      name: this.name,
      path: path,
      query,
      params: {
        ...(this.params || {}),
      },
      forceQueryParams: this.forceQueryParams,
    }
  }

  moveSingleFacetToPath() {
    if (
      this.name ||
      !this.query?.facet ||
      this.query?.facet.length === 0 ||
      (Array.isArray(this.query?.facet) && this.query?.facet.length > 1)
    ) {
      return {
        path: this.path,
        query: this.query,
      }
    }
    const query = {
      ...this.$route.query,
      ...(this.query || {}),
    }
    const facetPath = `-/${query.facet}`
    delete query.facet
    return {
      path: (this.path ? this.path : '/') + facetPath,
      query,
    }
  }
}
