import { RouteRecord } from 'vue-router'

export interface Route {
  name?: string | null
  path: string
  fullPath?: string | null
  query: Record<string, string | Array<string | null>>
  params: Record<string, string>
  meta?: any
  matched?: RouteRecord[]
}

export function isSearchRoute(route: Route) {
  return routeMatchesApp(route, 'search') || routeMatchesApp(route, 'maps')
}

export function isCampsiteDetailsRoute(route: Route) {
  return routeMatchesApp(route, 'campsite-details')
}

function routeMatchesApp(route: Route, app: string) {
  return route?.matched
    ? route.matched.some((match) => match.meta?.app === app)
    : false
}
