export function getPathElements(route) {
  var p = route.params;
  // route matching params can be a series of h1/h2/h3/h4 + facets
  if (p.h1 || p.facets) {
    var pathJoined = [p.h1, p.h2, p.h3, p.h4].filter(h => !!h).join('/');
    return [pathJoined ? "".concat(pathJoined, "/") : '', p.facets];
  }
  // or a catch all
  var path = route.params.pathMatch || '';
  path = !path || path.endsWith('/') ? path : path + '/';
  var [hierarchyPath, facetPath] = path.split('-/', 2);
  return [hierarchyPath, facetPath];
}