import { Context } from '@nuxt/types'

export default ({ $routerClient, app, route }: Context) => {
  app.watch = {
    $route: (next: typeof route) => {
      $routerClient.routeChanged(next)
    },
  }
  $routerClient.routeChanged(route)
}
